function ListPanel(element) {
    WebPageComponent.call(this, element);

    this.determineItems = function () {
        var query = new DomQuery(this.element);
        var items = query.getChildren(WithClass("Item"));

        for (var index = 0; index < items.length; index++) {
            var item = items[index];

            if (item.classList.contains("Add"))
                item.childNodes[0].onclick = this.createToggleSectionHandler(item)
        }
    };

    this.createToggleSectionHandler =function (element) {
        return function(event) {
            if (element.classList.contains("Expanded"))
                element.classList.remove("Expanded");
            else
                element.classList.add("Expanded");
        };
    }

    this.determineItems();
}

interactivityRegistration.register("ListPanel", function (element) { return new ListPanel(element); });
