function ValueTextRenderer () {
    this.escape = function(value) {
        let result = "";
        const escapedCharacters = "()\\,";

        for (const character of value) {
            if (escapedCharacters.includes(character))
                result += "\\";

            result += character;
        }

        return result;
    }

    this.render = function(value) {
        if (value === undefined)
            throw new Error("Undefined value cannot be rendered to text");
        else if (value === null)
            return "";
        else {
            const type = typeof value;

            // TODO: We currently only support null, boolean, number and string. We should support other types as well.
            if (type === "boolean")
                return this.renderBoolean(value);
            else if (type === "number")
                return value.toString();
            else if (type === "string")
                return this.escape(value);
            else if (type === "object")
                return value.toString();
            else
                return String(value);
        }
    }

    this.renderBoolean = function (value) {
        if (value)
            return "True";
        else
            return "False";
    }
}
