function ConsentWindow(element) {
    WebPageComponent.call(this, element);

    this.attachHandlers = function() {
        var object = this;

        this.button.onclick = function(event) { object.consent() };
    }

    this.consent = function() {
        var object = this;
        var request = new XMLHttpRequest();

        var formData = new FormData();
        formData.append("Consent", true);

        request.open("POST", this.element.dataset.Uri, true);
        request.onload = function() {
            window.location = object.element.dataset.Uri;
        }
        request.send(formData);
    }

    this.determineElements = function() {
        this.button = new DomQuery(this.element).getChild(WithClass("Consent"));
    }

    this.determineElements();
    this.attachHandlers();
}

interactivityRegistration.register("ConsentWindow", function (element) { return new ConsentWindow(element); });
