function SuggestFieldValue(identifier, element) {
  this.build = function (row) {
    if (this.element !== undefined)
      interactivityRegistration.detach(this.element);

    this.element = document.createElement("tr");

    for (var child of row.childNodes)
      this.element.appendChild(child.cloneNode(true));

    this.element.className = "Value";
    this.element.classList.add("Card");

    interactivityRegistration.attach(this.element);
  }

  this.identifier = identifier;
  this.element = element;
}

function SuggestFieldValueList(element, table, supportsMultipleValues) {
  this.determineElements = function () {
    var query = new DomQuery(this.element);

    this.input = query.getDescendant(WithClass("InputValue"));
    this.valuesElement = query.getDescendant(WithClass("Values"));

    query = new DomQuery(this.valuesElement.tBodies[0]);

    var values = query.getChildren(WithClass("Value"));

    for (var index = 0; index < values.length; index++)
      this.registerValueElement(values[index]);

    if (this.table !== null)
      this.synchronizeTableRowSelection();
  }

  this.clearClicked = function (event, value) {
    var index = this.values.indexOf(value);

    if (index !== -1)
      this.delete(index);

    this.updateValue();
    getEvent(event).stopHandling();
  }

  this.addClearButton = function (value) {
    var object = this;

    var clearButton = document.createElement("div");
    clearButton.className = "Clear";
    clearButton.onclick = function (event) { object.clearClicked(getEvent(event), value); };

    var cell = value.element.insertCell();
    cell.appendChild(clearButton);
  }

  this.registerValueElement = function (element) {
    var value = new SuggestFieldValue(element.dataset.Identifier, element);
    this.values.push(value);

    var button = new DomQuery(element).getDescendant(WithClass("Clear"));

    if (button !== null) {
      var object = this;
      button.onclick = function (event) { object.clearClicked(getEvent(event), value); };
    }
  }

  this.add = function (identifier, row) {
    var value = new SuggestFieldValue(identifier);
    value.build(row);

    this.addClearButton(value);

    if (!this.supportsMultipleValues && this.values.length > 0)
      this.delete(0);

    this.values.push(value);

    this.valuesElement.tBodies[0].appendChild(value.element);
    this.updateValue();
  }

  this.delete = function (index) {
    var body = this.valuesElement.tBodies[0];

    this.values.splice(index, 1);
    body.removeChild(body.childNodes[index]);
  }

  this.updateValue = function () {
    this.input.value = "";

    for (var index = 0; index < this.values.length; index++) {
      var value = this.values[index];

      if (index > 0)
        this.input.value += ",";

      this.input.value += value.identifier;
    }

    // TODO: We should use new Event() instead, however this is not supported in IE.

    this.synchronizeTableRowSelection();

    var event = new Event("change");
    this.input.dispatchEvent(event);

    var event = new Event("input");
    this.input.dispatchEvent(event);
  }

  this.getIdentifiers = function () {
    var result = new Array();

    for (var index = 0; index < this.values.length; index++)
      result.push(this.values[index].identifier);

    return result;
  }

  this.hasIdentifier = function (identifier) {
    return this.getIdentifiers().indexOf(identifier) > -1;
  }

  this.extractWithIdentifier = function (identifier) {
    var index = this.getIdentifiers().indexOf(identifier);

    if (index !== -1)
      this.delete(index);

    this.updateValue();
  }

  this.removeLast = function () {
    this.delete(this.values.length - 1);
    this.updateValue();
  }

  this.synchronizeTableRowSelection = function () {
    rows = this.table.tableElement.rows;

    for (var index = 0; index < rows.length; index++) {
      var row = rows[index];

      setNodeClassEnabled(row, "Selected", this.hasIdentifier(row.dataset.Identifier));
    }
  }

  this.element = element;
  this.input = null;
  this.values = new Array();
  this.table = table;
  this.supportsMultipleValues = supportsMultipleValues;

  this.determineElements();
}

function SuggestField(element) {
  WebPageComponent.call(this, element);

  this.addEventListener = function (event, handler) {
    this.values.input.addEventListener(event, handler);
  }

  this.determineElements = function () {
    this.element.field = this;
    var query = new DomQuery(this.element);

    this.icon = query.getDescendant(WithClass("Icon"));

    this.division = query.getDescendant(WithClass("InnerDropDown"));
    this.outerDropDown = query.getDescendant(WithClass("OuterDropDown"));
    this.popup = new PopUp(this.division, this.outerDropDown, true);

    this.input = query.getDescendant(WithClass("TextInput"))
    this.table = this.determineTable();

    this.values = new SuggestFieldValueList(this.element, this.table, this.supportsMultipleValues);

    var classes = new HtmlClasses(this.element);
    classes.add(ControlMode.toText(this.mode));
  }

  this.determineTable = function () {
    var result = null;

    if (this.division !== null)
      result = this.division.getElementsByTagName("table")[0].parentNode.parentNode.component;

    return result;
  }

  this.focus = function () {
    this.input.focus();
  }

  this.getName = function () {
    return this.name;
  }

  this.getValue = function () {
    return this.values.input.value;
  }

  this.setValue = function (value) {
    this.values.input.value = value;
  }

  this.toggle = function () {
    if (this.popup.isOpen())
      this.close();
    else
      this.open();
  }

  this.open = function () {
    this.table.assureRendered();
    this.popup.open();
  }

  this.close = function () {
    this.popup.close();
  }

  this.clickOutsideListener = null;

  this.iconClicked = function (event) {
    this.toggle();
  }

  this.onTextInputInput = function (event) {
    this.open();
    getEvent(event).stopHandling();
    return this.table.searchTextChanged(this.input);
  }

  this.onTextInputKeyDown = function (event) {
    if (event.code === "ArrowDown" && this.popup.isOpen() && this.rows.length > 0) {
      this.rows[0].focus();
      event.stopHandling();
    }
  }

  this.makeRowsInteractive = function (table) {
    for (var i = 0; i < table.tBodies.length; i++) {
      var body = table.tBodies[i];

      for (var j = 0; j < body.rows.length; j++) {
        row = body.rows[j];
        row.tabIndex = "0";
        row.onkeydown = this.createRowOnKeyDownHandler(row);
        row.onclick = this.createRowClickedHandler(row);
        row.onmouseover = this.createRowHotHandler(row, true);
        row.onmouseout = this.createRowHotHandler(row, false);

        addNodeClass(row, "SuggestRow");
      }
    }
  }

  this.createRowOnKeyDownHandler = function (row) {
    var field = this;

    return function (event) {
      if (event.code === "Space") {
        field.rowClicked(event, row);
        event.stopHandling();
      }
      else if (event.code === "Enter" || event.code === "Tab") {
        field.rowClicked(event, row);
        event.stopHandling();

        field.close();
        field.input.value = "";
        field.input.focus();
        field.table.searchTextChanged(field.input);
      }
    };
  }

  this.createRowClickedHandler = function (row) {
    return (event) => {
      this.rowClicked(event, row);
      this.close();
      this.input.value = "";
      this.input.focus();

      event.stopPropagation();
    }
  }

  this.createRowHotHandler = function (row, hot) {
    return function (event) {
      setHot(row, event, hot);
    };
  }

  this.rowClicked = function (event, row) {
    var event = getEvent(event);
    var eventSource = event.getSource();

    if (!isLink(eventSource)) {
      var identifier = row.dataset.Identifier;

      if (this.values.hasIdentifier(identifier)) {
        this.values.extractWithIdentifier(identifier);
      }
      else
        this.addValue(identifier, row);

      event.stopHandling();
    }
  }

  this.addValue = function (value, row) {
    this.values.add(value, row);
  }

  this.findRowWithKey = function (table, key) {
    var result = null;
    var i = 1;

    while (result === null && i < table.rows.length) {
      var row = table.rows[i];

      if (row.dataset.Identifier == key)
        result = row;
      else
        i++;
    }

    return result;
  }

  this.createOnTableChangedHandler = function () {
    var field = this;

    return function (newTable) {
      field.tableChanged(newTable);
    }
  }

  this.tableChanged = function (newTable) {
    this.makeRowsInteractive(newTable);
    this.values.synchronizeTableRowSelection();
  }

  this.handleHotKey = function (event) {
    if (this.popup.isOpen() && event.code === "Escape") {
      this.close();
      this.input.focus();
      event.stopHandling();
    }
    else if (event.code === "Space" && event.ctrlKey) {
      this.open();

      if (this.rows.length > 0)
        this.rows[0].focus();

      event.stopHandling();
    }
    else if (event.code === "Backspace" && this.input === document.activeElement && this.input.value.length == 0) {
      if (this.values.values.length > 0) {
        this.values.removeLast();
        this.input.focus();
      }

      event.stopHandling();
    }
  }

  this.attachEventHandlers = function () {
    var object = this;

    if (this.mode === ControlMode.edit) {
      this.element.addEventListener("keydown", function (event) { object.handleHotKey(getEvent(event)); });
      this.icon.addEventListener("click", function (event) { object.iconClicked(getEvent(event)); });

      if (this.input !== null) {
        this.input.addEventListener("input", function (event) { object.onTextInputInput(getEvent(event)); });
        this.input.addEventListener("keydown", function (event) { object.onTextInputKeyDown(getEvent(event)); });

        this.input.addEventListener("focus", function (event) { object.element.classList.add("Focus") });
        this.input.addEventListener("blur", function (event) { object.element.classList.remove("Focus") });
      }
    }
  }

  this.initialize = function () {
    this.table.onChanged = this.createOnTableChangedHandler();
    var table = this.table.getTableElement();

    this.makeRowsInteractive(table);
  }

  this.table = null;
  this.icon = null;
  this.input = null;
  this.division = null;
  this.values = null;

  this.name = this.element.dataset.Name;
  this.supportsMultipleValues = this.element.dataset.SupportsMultipleValues === "true" && this.mode === ControlMode.edit;
  this.determineElements();
  this.attachEventHandlers();

  if (this.mode === ControlMode.edit)
    this.initialize();

  Object.defineProperty(this, "rows", { get: function () { return this.table.tableElement.tBodies[0].rows; } });
}

interactivityRegistration.register("Suggest", function (element) { return new SuggestField(element); });
