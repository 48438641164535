function Uri(uri) {
    this.addQueryParameter = function(name, value) {
        if (this.uri.indexOf("?") == -1)
            this.uri = this.uri + "?" + encodeURI(name) + "=" + encodeURI(value);
        else
            this.uri = this.uri + "&" + encodeURI(name) + "=" + encodeURI(value);

        return this.uri;
    }

    this.uri = uri;
}
