function UploadForm(element) {
  WebPageComponent.call(this, element);

  this.attachHandlers = function () {
    var form = this;

    for (var i = 0; i < this.fields.length; i++) {
      var field = this.fields[i];

      if (field.name.slice(0, 7) == "Upload-") {
        if (field.type == "file") {
          form.fileField = field;
          field.onchange = function (event) { form.fileChanged() };
        }
        else
          field.onchange = function (event) { form.inputChanged() };
      }
    }
  }

  this.determineElements = function () {
    var query = new DomQuery(this.element);

    this.fields = query.getDescendants(WithTagName("INPUT"));
  }

  this.inputChanged = function (event) {
    var form = this;
    var xmlRequest = new newXmlRequest();

    var formData = new FormData(form.element);

    xmlRequest.open("POST", this.uri, true);
    xmlRequest.onreadystatechange = function () { form.render(xmlRequest); };
    xmlRequest.send(formData);
  }

  this.fileChanged = function (event) {
    var uploadArea = this.element.parentNode;
    var files = this.fileField.files;

    uploadArea.component.processFiles(files);
  }

  this.refresh = function () {
    var form = this;
    var xmlRequest = new newXmlRequest();

    xmlRequest.open("GET", this.uri, true);
    xmlRequest.onreadystatechange = function () { form.render(xmlRequest); };
    xmlRequest.send();
  }

  this.render = function (xmlRequest) {
    if (xmlRequest.readyState == 4) {
      var newFormDivision = document.createElement("div");
      newFormDivision.innerHTML = xmlRequest.responseText;

      interactivityRegistration.detach(this.element);
      var newForm = newFormDivision.firstChild;

      this.element.parentElement.replaceChild(newForm, this.element);
      this.element = newForm;

      interactivityRegistration.attach(newForm);
    }
  }

  this.element.component = this;

  this.fields = null;
  this.fileField = null;

  this.uri = this.element.dataset.Uri;
  this.determineElements();
  this.attachHandlers();
}

class Form extends WebPageComponentClass {
  constructor(element) {
    super(element);

    this.initialize();
  }

  initialize() {
    this.loadingSwitch = new HtmlClassSwitch(this.element, "Loading");

    if (this.asynchronous)
      this.initializeAsynchronous();
    else
      this.initializeSynchronous();
  }

  initializeAsynchronous() {
    this.element.addEventListener(
      "submit",
      (event) => {
        event.preventDefault();

        if (!this.loading) {
          this.loading = true;
          const formData = new FormData(this.element);

          fetch(
            this.uri,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded"
              },
              body: new URLSearchParams(formData).toString(),
              redirect: "manual"
            }
          )
            .then((response) => this.reloadAsynchronous(response));
        }
      }
    )
  }

  async reloadAsynchronous(response) {
    if (response.ok) {
      application.toastBox.addMessage(new ToastMessage(this.element.dataset.SuccessfulMessage, "Success"));
      await this.reloadForm(response);
    }
    else if (response.status === 400) {
      application.toastBox.addMessage(new ToastMessage(this.element.dataset.WarningMessage, "Warning"));
      await this.reloadForm(response);
    }
    else if (response.status >= 300 && response.status < 400)
      application.pageHandler.load(response.headers.get("Location"));
    else
      application.toastBox.addMessage(new ToastMessage(this.element.dataset.ErrorMessage, "Error"));

    this.loading = false;
  }

  async reloadForm(response) {
    const dummyElement = document.createElement("div");
    dummyElement.innerHTML = await response.text();

    const form = dummyElement.firstChild;

    interactivityRegistration.detach(this.element);
    this.element.parentElement.replaceChild(form, this.element);

    form.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });

    interactivityRegistration.attach(form);
  }

  initializeSynchronous() {
    this.alreadySubmitted = false;
    this.element.addEventListener(
      "submit",
      (event) => {
        if (this.alreadySubmitted) {
          event.preventDefault();
          alert("Form already submitted");

          return false;
        }
        else {
          this.alreadySubmitted = true;
          return true;
        }
      }
    );
  }

  handleEvent(event) {
    if (event instanceof VisibilityChangedEvent) {
      const element = this.getElementWithComponent(event.field);

      if (element !== null)
        this.updateVisibility(event.field, element);
    }
    else
      super.handleEvent(event);
  }

  getElementWithComponent(component) {
    let result = null;
    let index = 0;

    while (result === null && index < this.element.childNodes.length) {
      const node = this.element.childNodes[index];

      if (node.contains(component.element))
        result = node;
      else
        index++;
    }

    return result;
  }

  updateVisibility(field, element) {
    const visibility = new HtmlClassSwitch(element, "Hidden");
    visibility.setStatus(!field.visible);
  }

  focus() {
    const target = this.childComponents.find(child => child.isFocusable());

    if (target !== undefined)
      target.focus();
  }

  get asynchronous() {
    return this.element.dataset.Asynchronous === "true";
  }

  get uri() {
    return this.element.dataset.Uri;
  }

  get loading() {
    return this.loadingSwitch.getStatus();
  }

  set loading(value) {
    this.loadingSwitch.setStatus(value);
  }
}

interactivityRegistration.register("Form", function (element) { return new Form(element); });
interactivityRegistration.register("UploadForm", function (element) { return new UploadForm(element); });
