function retrieveFile(uri, name, callback) {
    var blob = null;
    var request = new XMLHttpRequest();
    request.responseType = "blob";

    request.open("GET", uri, true);
    request.onload = function() {
        blob = request.response;
        callback(new File([blob], name, {type: "application/octet-stream", lastModified: Date.now()}));
    }
    request.send();
}

function ProgressBar(phases) {
    this.createElement = function () {
        result = document.createElement("div");
        result.className = "ProgressBar"

        for (let index = 0; index < this.phases; index++) {
            const indicator = result.appendChild(document.createElement("div"));
            indicator.className = "Indicator";
            indicator.style.opacity = Math.pow(0.5, this.phases - index);

            this.indicators.push(indicator);
        }

        this.indicator = result.appendChild(document.createElement("div"));
        this.indicator.className = "Indicator";
        this.indicator.innerHTML = "0%";

        return result;
    }

    this.setProgress = function (progress, phase) {
        for (let index = 0; index < progress.length; index++) {
            this.indicators[index].style.width = progress[index] + "%";

            if (index === phase) {
                this.progress = progress[index];
                this.indicator.innerHTML = (progress[index].toFixed(2)) + "%";
            }
        }
    }

    this.phases = phases;
    this.progress = 0;
    this.indicators = new Array();
    this.element = this.createElement();
}

function FileField(element) {
    FileDropZone.call(this, element);

    this.attachHandlers = function() {
        var object = this;

        if (this.mode === "edit") {
            this.icon.onclick = function(event) { clickElement(object.input) }
            this.clear.onclick = function(event) { object.setValue(null); }
            this.input.onchange = function(event) { object.valueChanged() }

            this.fileName.onclick = function (event) {
                if (object.value != null) {
                    object.openPreview();
                    event.stopPropagation();
                };
            }
        }
        else {
            if (this.value !== null) {
                this.fileName.onclick = function (event) {
                    object.openPreview();
                    event.stopPropagation();
                };

                this.fileName.draggable = true;
                this.fileName.addEventListener(
                    "dragstart",
                    function(event) {
                        event.dataTransfer.setData("DownloadURL", "application/octet-stream:" + object.getFileName() + ":" + object.getUri());
                        event.dataTransfer.setData("File", object.getUri());
                        event.dataTransfer.setData("FileName", object.getFileName());
                    },
                    false
                );
            }
        }

        this.preview.onscroll = function (event) { event.stopPropagation(); };
    }

    this.createButton = function (caption) {
        var result = document.createElement("input");
        result.type = "button";
        result.value = caption;

        return result;
    }

    this.createDownloadButton = function () {
        var result = document.createElement("a");
        result.classList.add("button");
        result.classList.add("Action");
        result.href = this.uri;
        result.download = this.getFileName();
        result.innerHTML = "Download";

        return result;
    }

    this.enableDownloadButton = function (enabled) {
        new HtmlClassSwitch(this.downloadButton, "Disabled").setStatus(!enabled);

        if (enabled)
            this.downloadButton.href = this.uri;
        else
            this.downloadButton.href = "javascript:void(0);";
    }

    this.createToolBar = function () {
        this.downloadButton = this.createDownloadButton();

        var fileNameLabel = document.createElement("span");
        fileNameLabel.classList.add("FileName");
        fileNameLabel.innerHTML = this.getFileName();

        var result = document.createElement("div");
        result.classList.add("ToolBar");
        result.appendChild(this.downloadButton);
        result.appendChild(fileNameLabel);

        return result;
    }

    this.build = function() {
        this.element.innerHTML = "";

        this.fileName = document.createElement("div");
        this.fileName.classList.add("FileName");

        if (this.value !== null)
            this.fileName.innerHTML = this.value;

        if (this.mode === "edit") {
            this.valueField = document.createElement("input");
            this.valueField.type = "hidden";
            this.valueField.value = this.element.dataset.State;
            this.valueField.name = this.element.dataset.Name;

            this.input = document.createElement("input");
            this.input.type = "file";
            this.input.style.display = "none";

            this.icon = document.createElement("div");
            this.icon.classList.add("Icon");

            this.clear = document.createElement("div");
            this.clear.classList.add("Clear");

            this.progressBar = document.createElement("div");
            this.progressBar.classList.add("ProgressBar");

            if (this.value === null)
                this.fileName.innerHTML = this.hint;

            this.element.appendChild(this.valueField);
            this.element.appendChild(this.input);
            this.element.appendChild(this.icon);
            this.element.appendChild(this.fileName);
            this.element.appendChild(this.clear);
            this.element.appendChild(this.progressBar);
        }
        else
            this.element.appendChild(this.fileName);

        this.toolBar = this.createToolBar();

        this.previewContent = document.createElement("div");
        this.previewContent.classList.add("Contents");

        this.previewWindow = document.createElement("div");
        this.previewWindow.classList.add("Window");
        this.previewWindow.appendChild(this.toolBar);
        this.previewWindow.appendChild(this.previewContent);

        this.preview = document.createElement("div");
        this.preview.classList.add("Preview");
        this.preview.appendChild(this.previewWindow);
        this.preview.onclick = function (event) { event.stopPropagation(); };

        this.element.appendChild(this.preview);
    }

    this.getFileName = function () {
        return this.fileName.innerHTML;
    }

    this.getUri = function () {
        return this.uri + "/" + this.fileName.innerHTML;
    }

    this.handleFiles = function (files) {
        this.setValue(files[0]);
    }

    this.initialize = function () {
        this.mode = this.element.dataset.Mode;
        this.uri = this.element.dataset.Uri;
        this.hint = this.element.dataset.Hint;
        this.confirmationRequired = this.element.dataset.ConfirmationRequired === "true";

        if (this.element.dataset.Value !== undefined)
            this.value = this.element.dataset.Value;
        else
            this.value = null;

        if (this.mode === "edit" && this.value !== null)
                this.element.classList.add("Selected");

        this.initializeDropZone();
    }

    this.isEnabled = function () {
        return this.mode === "edit";
    }

    this.showPreview = function (content) {
        this.previewContent.innerHTML = content;
    }

    this.retrievePreview = function () {
        var object = this;
        var xmlHttpRequest = new XMLHttpRequest();
        xmlHttpRequest.open("GET", this.uri + "/Preview");

        xmlHttpRequest.onreadystatechange = function (event) {
            if (this.readyState === 4)
                object.showPreview(xmlHttpRequest.responseText);
        }

        xmlHttpRequest.send();
    }

    this.acceptConfirmation = function () {
        var object = this;
        var xmlHttpRequest = new XMLHttpRequest();
        xmlHttpRequest.open("POST", this.uri + "/Confirmation");

        xmlHttpRequest.onreadystatechange = function (event) {
            if (this.readyState === 4) {
                object.enableDownloadButton(true);
                object.confirmationRequired = false;
                object.retrievePreview();
            }
        }

        xmlHttpRequest.send();
    }

    this.showConfirmation = function (content) {
        var object = this;

        var confirmationContentsPanel = document.createElement("div");
        confirmationContentsPanel.classList.add("ConfirmationContentsPanel");
        confirmationContentsPanel.innerHTML = content;

        var confirmationMessage = new DomQuery(confirmationContentsPanel).getChild(WithClass("ConfirmationMessage"));
        var unconfirmableMessage = new DomQuery(confirmationMessage).getChild(WithClass("Unconfirmable"));

        var confirmable = confirmationMessage.dataset.Confirmable === "true";

        var acceptButton = this.createButton("Accept");
        acceptButton.disabled = !confirmable;
        acceptButton.onclick = function (event) {
            object.acceptConfirmation();
            event.stopPropagation();
        }

        var toolBar = document.createElement("div");
        toolBar.classList.add("ToolBar");
        toolBar.appendChild(acceptButton);

        if (unconfirmableMessage !== null)
            toolBar.appendChild(unconfirmableMessage);

        var confirmationPanel = document.createElement("div");
        confirmationPanel.classList.add("ConfirmationPanel");
        confirmationPanel.appendChild(confirmationContentsPanel);
        confirmationPanel.appendChild(toolBar);

        this.previewContent.innerHTML = "";
        this.previewContent.appendChild(confirmationPanel);
    }

    this.retrieveConfirmation = function () {
        var object = this;
        var xmlHttpRequest = new XMLHttpRequest();
        xmlHttpRequest.open("GET", this.uri + "/Confirmation");

        xmlHttpRequest.onreadystatechange = function (event) {
            if (this.readyState === 4)
                object.showConfirmation(xmlHttpRequest.responseText);
        }

        xmlHttpRequest.send();
    }

    this.openPreview = function () {
        var object = this;

        this.enableDownloadButton(!this.confirmationRequired);
        this.preview.classList.add("Expanded");

        var listener = connectClickOutsideListener(
            object.previewWindow,
            function(event) {
                object.preview.classList.remove("Expanded");
                object.previewContent.innerHTML = "";
                event.stopPropagation();
                removeClickOutsideListener(listener);
            }
        );

        if (this.confirmationRequired)
            this.retrieveConfirmation();
        else
            this.retrievePreview();
    }

    this.setProgress = function (progress) {
        this.progressBar.style.width = progress + "%";
    }

    this.setValue = function (file) {
        var object = this;

        var xmlHttpRequest = new XMLHttpRequest();
        xmlHttpRequest.open("POST", this.uri);

        var formData = new FormData();
        formData.append("form", "Form");

        if (file == null) {
            formData.append("Name", "");
            formData.append("Contents", "");

            xmlHttpRequest.onreadystatechange = function (event) {
                if (this.readyState === 4) {
                    object.element.classList.remove("Selected");
                    object.fileName.innerHTML = object.hint;
                    object.input.value = "";
                    object.valueField.value = "Empty";

                    if (object.valueField.onchange !== null)
                        object.valueField.onchange();
                }
            }
        }
        else {
            this.element.classList.add("Uploading");
            this.fileName.innerHTML = file.name;

            formData.append("Name", file.name);
            formData.append("Contents", file);

            xmlHttpRequest.upload.onprogress = function (event) {
                if (event.lengthComputable) {
                    var complete = (event.loaded / event.total * 100 | 0);
                    object.setProgress(complete);
                }
            };

            xmlHttpRequest.onreadystatechange = function (event) {
                if (this.readyState === 4) {
                    if (xmlHttpRequest.status === 200) {
                        object.element.classList.add("Selected");
                        object.value = file.name;
                        object.valueField.value = "Input";

                        if (object.valueField.onchange !== null)
                            object.valueField.onchange();
                    }
                    else {
                        object.value = null;
                        object.fileName.innerHTML = object.hint;
                    }

                    object.element.classList.remove("Uploading");
                    object.setProgress(0);
                }
            }
        }

        xmlHttpRequest.send(formData);
    }

    this.valueChanged = function () {
        if (this.input.files.length === 1)
            this.setValue(this.input.files[0]);
    }

    this.initialize();
    this.build();
    this.attachHandlers();
}

interactivityRegistration.register("FileField", function (element) { return new FileField(element); });
