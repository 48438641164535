class Diagram extends WebPageComponentClass {
  constructor(element) {
    super(element);

    this.toolbar = new DomQuery(this.element).getChild(WithClass("Toolbar"));
    this.contents = new DomQuery(this.element).getChild(WithClass("Contents"));

    this.updating = new HtmlClassSwitch(this.element, "Updating");
    this.loadingIndicator = new LoadingIndicator();
    this.element.appendChild(this.loadingIndicator.element);

    this.renderTime = this.element.dataset.RenderTime;
    this.diagramRenderer = new DiagramToImageRenderer(this);

    this.attachButtonHandlers();
  }

  determineComponents() {
    if (this.contents.childNodes.length === 2) {
      this.diagram = this.contents.childNodes[0].childNodes[0];
      this.legend = this.contents.childNodes[1];

      this.copyButton.disabled = !this.diagramRenderer.canCopy();
      this.downloadButton.disabled = false;
      this.shareButton.disabled = !this.diagramRenderer.canShare();
    }
  }

  attachButtonHandlers() {
    this.copyButton = new DomQuery(this.toolbar).getChild(WithClass("Copy"));
    this.copyButton.addEventListener("click", (event) => this.copy());
    this.copyButton.disabled = true;

    this.downloadButton = new DomQuery(this.toolbar).getChild(WithClass("Download"));
    this.downloadButton.addEventListener("click", (event) => this.download());
    this.downloadButton.disabled = true;

    this.shareButton = new DomQuery(this.toolbar).getChild(WithClass("Share"));
    this.shareButton.addEventListener("click", (event) => this.share());
    this.shareButton.disabled = true;
  }

  bind() {
    if (this.renderTime === "Deferred")
      this.refresh();
    else
      this.determineComponents();
  }

  copy() {
    this.diagramRenderer.copy();
  }

  download() {
    this.diagramRenderer.download("Diagram");
  }

  share() {
    this.diagramRenderer.share("Diagram");
  }

  reload(text) {
    const dummyElement = document.createElement("div");
    dummyElement.innerHTML = text;

    const contents = new DomQuery(dummyElement).getDescendant(WithClass("Contents"));

    this.element.replaceChild(contents, this.contents);
    this.contents = contents;

    this.determineComponents();
  }

  refresh() {
    this.updating.setStatus(true);
    this.loadingIndicator.setStatus(true);

    fetch(
      this.element.dataset.Uri,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "Command": "Refresh" })
      })
      .then((response) => response.text())
      .then(text => this.reload(text))
      .finally(() => {
        this.updating.setStatus(false);
        this.loadingIndicator.setStatus(false);
      });
  }

  handleEvent(event) {
    if (event instanceof DataChangedEvent && this.refreshMode == RefreshMode.ForSiblings)
      this.refresh();
    else
      super.handleEvent(event);
  }
}

class DiagramToImageRenderer {
  constructor(diagram) {
    this.component = diagram;
  }

  copy() {
    this.createImage(
      (canvas) => {
        canvas.toBlob(
          blob => {
            navigator.clipboard.write([new ClipboardItem({ "image/png": blob })])
              .then(() => { application.toastBox.addMessage(new ToastMessage("Diagram succesfully copied to clipboard", "Success")); })
              .catch((error) => { application.toastBox.addMessage(new ToastMessage("Failed to copy PNG image to clipboard", "Error")); });
          },
          "image/png"
        );
      }
    );
  }

  canCopy() {
    return 'ClipboardItem' in window;
  }

  canShare() {
    return navigator.share !== undefined;
  }

  download(name) {
    this.createImage(
      (canvas) => {
        const pngData = canvas.toDataURL("image/png");

        const downloadLink = document.createElement("a");
        downloadLink.href = pngData;
        downloadLink.download = name + ".png";
        downloadLink.click();
      }
    );
  }

  share(name) {
    this.createImage(
      (canvas) => {
        canvas.toBlob(
          (blob) => {
            if (navigator.share) {
              navigator.share({
                title: name,
                files: [new File([blob], name + ".png", { type: "image/png" })]
              });
            }
          },
          "image/png"
        );
      }
    )
  }

  applyComputedStyles(original, clone) {
    const computedStyle = window.getComputedStyle(original);

    for (let index = 0; index < computedStyle.length; index++) {
      const name = computedStyle[index];
      const value = computedStyle.getPropertyValue(name);

      clone.style[name] = value;
    }

    for (let index = 0; index < original.children.length; index++)
      this.applyComputedStyles(original.children[index], clone.children[index]);
  }

  createImage(onload) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const diagram = this.component.diagram.cloneNode(true);
    this.applyComputedStyles(this.component.diagram, diagram);

    const diagramWidth = this.component.diagram.getBoundingClientRect().width;
    const diagramHeight = this.component.diagram.getBoundingClientRect().height;
    const legendWidth = 350;

    const legend = this.component.legend.cloneNode(true);
    this.applyComputedStyles(this.component.legend, legend);
    legend.style.width = legendWidth + "px";
    legend.style.height = diagramHeight;

    canvas.width = diagramWidth + legendWidth;
    canvas.height = diagramHeight;

    const legendContainer = document.createElement("div");
    legendContainer.appendChild(legend);

    const container = document.createElement("div");
    container.style.background = "white";
    container.style.display = "flex";
    container.style.alignItems = "center";
    container.style.width = canvas.width + "px";
    container.appendChild(diagram);
    container.appendChild(legendContainer);

    const foreignObject = document.createElementNS("http://www.w3.org/2000/svg", "foreignObject");
    foreignObject.setAttribute("width", "100%");
    foreignObject.setAttribute("height", "100%");
    foreignObject.appendChild(container);

    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.appendChild(foreignObject);

    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svg);

    const image = new Image();
    image.src = "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svgString)));

    image.onload = function () {
      context.drawImage(image, 0, 0, canvas.width, canvas.height);
      onload(canvas);
    };
  }
}

function LegendItem(element) {
  WebPageComponent.call(this, element);

  this.determineElements = function () {
    var query = new DomQuery(this.element);

    this.icon = query.getDescendant(WithTagName("svg"));
    this.table = query.getDescendant(WithTagName("TABLE"));
  }

  this.attachEventHandlers = function () {
    var object = this;

    if (this.table != null) {
      var collapsed = new HtmlClassSwitch(this.table, "Expanded");
      this.icon.onclick = function (event) { collapsed.toggle(); };
    }
  }

  this.determineElements();
  this.attachEventHandlers();
}

interactivityRegistration.register("Diagram", function (element) { return new Diagram(element); });
interactivityRegistration.register("LegendItem", function (element) { return new LegendItem(element); });
