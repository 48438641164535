const SelectionMode = new Enumeration(["Start", "End"]);

function DatePeriodSelectorPage(periodLow, periodHigh, dayLabels, year, month, period) {
    this.determineStartDay = function () {
        let day = new Date(year, month, 1).getDay() + 6 % 7;

        if (day < 3)
            day = day + 7;

        if (day > 10)
            day = day - 7;

        return day;
    }

    this.determineEndDay = function () {
        return this.startDay + this.daysInMonth - 1;
    }

    this.dateToText = function (date) {
        const monthText = (date.getMonth() + 1).toString().padStart(2, "0");
        const dayText = date.getDate().toString().padStart(2, "0");

        return `${date.getFullYear()}-${monthText}-${dayText}`;
    }

    this.daysArray = function () {
        const array = new Array(42);
        const start = this.startDay;

        const date = new Date(year, month, 1);
        date.setDate(date.getDate() - start);

        for (let index = 0; index < array.length; index++) {
            array[index] = new Date(date.valueOf());
            date.setDate(date.getDate() + 1);
        }

        return array;
    }

    this.changeFocus = function (delta) {
        this.focusIndex(this.focusedIndex + delta);
    }

    this.focus = function (previousIndex) {
        this.buttons[previousIndex].tabIndex = -1;

        const button = this.buttons[this.focusedIndex];
        button.tabIndex = 0;
        button.focus();
    }

    this.focusIndex = function (index) {
        const previousIndex = this.focusedIndex;

        this.focusedIndex = index;
        this.focus(previousIndex);
    }

    this.focusLast = function () {
        this.focusIndex(this.startDay + this.daysInMonth - 1);
    }

    this.focusFirst = function () {
        this.focusIndex(this.startDay);
    }

    this.handleDateClick = function (date) {
        return () => this.setDate(date);
    }

    this.headerRow = function () {
        const row = new HTMLTableRow();
        row.addArrayAsHeaderCells(this.dayLabels);

        return row.render();
    }

    this.isToday = function (date) {
        return this.equalDates(this.referenceDate, date);
    }

    this.equalDates = function(left, right) {
        return left.getFullYear() === right.getFullYear() && left.getMonth() === right.getMonth() && left.getDate() === right.getDate();
    }

    this.render = function (table) {
        this.buttons = new Array();
        table.addHeaderRow(this.headerRow());

        let index = 0;
        const daysArray = this.daysArray();

        while (index < daysArray.length) {
            const row = new HTMLTableRow();

            for (let weekDay = 0; weekDay < 7; weekDay++) {
                const date = daysArray[index];

                const label = document.createElement("span");
                label.innerText = date.getDate();

                const button = document.createElement("button");
                button.appendChild(label);
                button.onclick = this.handleDateClick(date);
                button.tabIndex = -1;

                this.buttons.push(button);

                const cell = document.createElement("td");
                cell.appendChild(button);

                if (date.getMonth() !== this.month)
                    cell.classList.add("OtherMonth");
                else {
                    if (this.period.start !== null && date.getTime() === this.period.start.getTime())
                        cell.classList.add("PeriodStart");

                    if (this.period.end !== null && date.getTime() === this.period.end.getTime())
                        cell.classList.add("PeriodEnd");

                    if (this.period.start !== null && this.period.end !== null && date.getTime() > this.period.start.getTime() && date.getTime() < this.period.end.getTime())
                        cell.classList.add("InPeriod");
                }

                if (this.isToday(date))
                    cell.classList.add("Today");

                row.addTableData(cell);
                index++;
            }

            table.addRow(row.render());
        }

        return table.render();
    }

    this.setDate = function (date) {
        if (this.period.mode === SelectionMode.Start) {
            this.setValue(this.periodLow, date);

            if (date > this.period.end)
                this.setValue(this.periodHigh, null);

            this.period.mode = SelectionMode.End;
        }
        else if (this.period.mode === SelectionMode.End) {
            if (date < this.period.start)
                this.setValue(this.periodLow, date);
            else {
                this.setValue(this.periodHigh, date);
                this.period.callback();
            }
        }
    }

    this.setValue = function(field, date) {
        if (date !== null)
            field.value = this.dateToText(date);
        else
            field.value = '';

        field.dispatchEvent(new Event("input"));
        field.dispatchEvent(new Event("change"));
    }

    this.periodLow = periodLow;
    this.periodHigh = periodHigh;
    this.period = period;

    this.dayLabels = dayLabels;
    this.month = month;

    this.daysInMonth = daysInMonth(year, month);
    this.startDay = this.determineStartDay();
    this.endDay = this.determineEndDay();
    this.referenceDate = new Date();
    this.focusedIndex = this.startDay;
}

function PeriodSelector(periodLow, periodHigh, monthLabels, dayLabels, todayLabel, callback) {
    this.changeMonth = function (index) {
        const date = this.referenceDate.getDate();
        this.referenceDate.setMonth(this.referenceDate.getMonth() + index);

        if (this.referenceDate.getDate() < date) {
            this.referenceDate.setDate(0);
        }
    }

    this.create = function () {
        this.element.className = "DateSelector";
        this.element.tabIndex = "0";

        this.currentNode = this.refreshPage();
        this.element.appendChild(this.currentNode);

        this.periodLow.addEventListener(
            "input",
            (event) => {
                const value = this.parseDate(this.periodLow.value);

                this.period.start = value;
                this.refresh();
            }
        );

        this.periodHigh.addEventListener(
            "input",
            (event) =>
            {
                const value = this.parseDate(this.periodHigh.value);

                this.period.end = value;
                this.refresh();
            }
        );
    }

    this.createKeyHandler = function () {
        return (event) => this.handleKey(event);
    }

    this.handleClickChangeMonthEvent = function (link, index) {
        return (event) => {
            this.changeMonth(index);
            this.refresh();

            getEvent(event).stopHandling();
        }
    }

    this.handleKey = function (event) {
        if (event.code === "ArrowLeft") {
            if (this.page.focusedIndex > this.page.startDay)
                this.page.changeFocus(-1);
            else {
                this.changeMonth(-1);
                this.refresh();
                this.page.focusLast();
            }

            event.preventDefault();
        }
        else if (event.code === "ArrowRight") {
            if (this.page.focusedIndex < this.page.endDay)
                this.page.changeFocus(1);
            else {
                this.changeMonth(1);
                this.refresh();
                this.page.focusFirst();
            }

            event.preventDefault();
        }
        else if (event.code === "ArrowUp") {
            if (this.page.focusedIndex >= this.page.startDay + 7)
                this.page.changeFocus(-7);
            else {
                const delta = this.page.startDay - (this.page.focusedIndex - 7);

                this.changeMonth(-1);
                this.refresh();
                this.page.changeFocus(this.page.daysInMonth - delta);
            }

            event.preventDefault();
        }
        else if (event.code === "ArrowDown") {
            if (this.page.focusedIndex <= this.page.endDay - 7)
                this.page.changeFocus(7);
            else {
                const delta = this.page.focusedIndex - this.page.endDay + 7;

                this.changeMonth(1);
                this.refresh();
                this.page.changeFocus(delta - 1);
            }

            event.preventDefault();
        }
        else if (event.code === "Home") {
            this.page.focusFirst();
            event.preventDefault();
        }
        else if (event.code === "End") {
            this.page.focusLast();
            event.preventDefault();
        }
    }

    this.header = function () {
        const fastBackward = document.createElement("button");
        const backward = document.createElement("button");
        const caption = document.createElement("span");
        const forward = document.createElement("button");
        const fastForward = document.createElement("button");

        fastBackward.innerHTML = "&lt;&lt;";
        fastBackward.onclick = this.handleClickChangeMonthEvent(fastBackward, -12);

        backward.innerHTML = "&lt;";
        backward.onclick = this.handleClickChangeMonthEvent(backward, -1);

        forward.innerHTML = "&gt;";
        forward.onclick = this.handleClickChangeMonthEvent(forward, 1);

        fastForward.innerHTML = "&gt;&gt;";
        fastForward.onclick = this.handleClickChangeMonthEvent(fastForward, 12);

        caption.innerHTML = this.referenceDate.getFullYear() + " " + this.monthLabels[this.referenceDate.getMonth()];
        caption.className = "caption";

        const result = new HTMLTableRow();
        const fastBackwardTableData = new HTMLTableData();
        const backwardTableData = new HTMLTableData();
        const captionTableData = new HTMLTableData();
        const forwardTableData = new HTMLTableData();
        const fastForwardTableData = new HTMLTableData();

        captionTableData.setColSpan(3);
        fastBackwardTableData.appendChild(fastBackward);
        backwardTableData.appendChild(backward);
        captionTableData.appendChild(caption);
        forwardTableData.appendChild(forward);
        fastForwardTableData.appendChild(fastForward);

        result.addTableData(fastBackwardTableData.render());
        result.addTableData(backwardTableData.render());
        result.addTableData(captionTableData.render());
        result.addTableData(forwardTableData.render());
        result.addTableData(fastForwardTableData.render());

        return result.render();
    }

    this.parseDate = function (dateString) {
        let date = null;

        if (dateString.length === 10) {
            const parts = dateString.split("-");

            if (parts.length === 3)
                date = new Date(parts[0], parts[1] - 1, parts[2]);
        }

        return date;
    }

    this.refresh = function () {
        const newNode = this.refreshPage();

        this.element.replaceChild(newNode, this.currentNode);
        this.currentNode = newNode;
        this.page.focus(0);
    }

    this.refreshPage = function () {
        const table = new HTMLTable();
        table.addHeaderRow(this.header());
        table.tableBody.addEventListener("keydown", this.createKeyHandler());

        this.page = new DatePeriodSelectorPage(this.periodLow, this.periodHigh, this.dayLabels, this.referenceDate.getFullYear(), this.referenceDate.getMonth(), this.period);
        this.page.render(table);

        const result = document.createElement("div");
        result.appendChild(table.render());

        return result;
    }

    this.setSelectionMode = function(mode) {
        this.period.mode = mode;
        this.refresh();
    }

    this.periodLow = periodLow;
    this.periodHigh = periodHigh;

    this.monthLabels = monthLabels;
    this.dayLabels = dayLabels;
    this.todayLabel = todayLabel;
    this.element = document.createElement("div");
    this.expanded = new HtmlClassSwitch(this.element, "Expanded");

    this.period = {
        start: this.parseDate(this.periodLow.value),
        end: this.parseDate(this.periodHigh.value),
        mode: SelectionMode.Start,
        callback: callback
    }

    if (this.period.start !== null)
        this.referenceDate = new Date(this.period.start.getTime());
    else if (this.period.end !== null)
        this.referenceDate = new Date(this.period.end.getTime());
    else
        this.referenceDate = new Date();

    this.create();
    this.setSelectionMode(this.period.mode);
}

function DatePeriodField(element) {
    WebPageComponent.call(this, element);

    this.attachEventHandlers = function () {
        this.button.addEventListener("click", (event) => this.toggleSelector());
        this.submitButton.addEventListener("click", (event) => this.submit());

        this.start.addEventListener("change", (event) => this.input.value = this.determineValue());
        this.end.addEventListener("change", (event) => this.input.value = this.determineValue());

        for (const defaultPeriod of this.defaultPeriods) {
            defaultPeriod.addEventListener(
                "click",
                (event) => {
                    this.start.value = defaultPeriod.dataset.PeriodStart;
                    this.start.dispatchEvent(new Event("input"));

                    this.end.value = defaultPeriod.dataset.PeriodEnd;
                    this.end.dispatchEvent(new Event("input"));

                    this.input.value = this.determineValue();
                    this.submit();
                }
            );
        }
    }

    this.createSelector = function() {
        this.periodSelector = new PeriodSelector(this.start, this.end, this.monthLabels, this.dayLabels, this.todayLabel, () => this.submit());
        this.selector.appendChild(this.periodSelector.element);
    }

    this.determineElements = function () {
        const query = new DomQuery(this.element);

        this.button = query.getChild(WithTagName("BUTTON"));
        this.selector = query.getChild(WithClass("PeriodSelector"));

        this.start = query.getChild(WithClass("Start"));
        this.end = query.getChild(WithClass("End"));

        this.input = document.createElement("input");
        this.input.setAttribute("type", "hidden");
        this.input.setAttribute("name", this.element.dataset.Name);
        this.input.value = this.determineValue();

        this.element.appendChild(this.input);

        this.submitButton = query.getDescendant(WithClass("Submit"));
        this.defaultPeriods = query.getDescendants(WithClass("DefaultPeriod"));
    }

    this.determineValue = function () {
        const renderer = new ValueTextRenderer();

        const start = this.start.value;
        const end = this.end.value;

        if (start.length > 0 || end.length > 0)
            return renderer.render(start) + ',' + renderer.render(end);
        else
            return "";
    }

    this.getName = function () {
        return this.element.dataset.Name;
    }

    this.getValue = function () {
        return this.input.value;
    }

    this.initializeLabels = function () {
        this.labels = new DomQuery(this.element).getDescendants(WithClass("Label"));

        this.monthLabels = new Array();
        this.dayLabels = new Array();

        let labelIndex = 0;

        for (let index = 1; index <= 12; index++) {
            this.monthLabels.push(this.labels[labelIndex].innerHTML);
            labelIndex++;
        }

        for (let index = 1; index <= 7; index++) {
            this.dayLabels.push(this.labels[labelIndex].innerHTML);
            labelIndex++;
        }

        this.todayLabel = this.labels[labelIndex].innerHTML;
    }

    this.submit = function () {
        this.toggleSelector();
        this.element.dispatchEvent(new Event("change"));
    }

    this.toggleSelector = function () {
        if (this.selector.classList.contains("Expanded")) {
            this.selector.classList.remove("Expanded");
            removeClickOutsideListener(this.clickOutsideListener);
        }
        else {
            this.selector.classList.add("Expanded");
            this.clickOutsideListener = connectClickOutsideListener(
                this.selector,
                () => this.toggleSelector()
            );
        }
    }

    if (this.mode === ControlMode.edit) {
        this.determineElements();
        this.initializeLabels();
        this.createSelector();
        this.attachEventHandlers();
    }
}

interactivityRegistration.register("DatePeriod", function (element) { return new DatePeriodField(element); });
