function MultipleSelectDropDown(element) {
    WebPageComponent.call(this, element);

    this.addEventListener = function(event, handler) {
        this.input.addEventListener(event, handler);
    }

    this.attachHandlers = function() {
        var object = this;
        this.button.addEventListener("click", function(event) { object.toggleForm(); });
        this.label.addEventListener("click", function(event) { object.toggleForm(); });

        for (var option of this.options) {
            option.element.addEventListener("click", this.createToggleOptionHandler(option));
            option.element.addEventListener("keydown", this.createOptionKeyHandler(option));
        }

        this.element.addEventListener("keydown", this.createKeyHandler());
    }

    this.close = function() {
        this.dropDownToggle.setStatus(false);
        removeClickOutsideListener(this.clickOutsideListener);
    }

    this.createKeyHandler = function() {
        var object = this;

        return function (event) {
            return object.handleKey(event);
        }
    }

    this.createOptionKeyHandler = function(option) {
        var object = this;

        return function (event) {
            return object.handleOptionKey(option, event);
        }
    }

    this.createToggleOptionHandler = function(option) {
        var object = this;

        return function(event) {
            object.toggle(option);
            return true;
        }
    }

    this.determineElements = function() {
        var query = new DomQuery(this.element);

        this.dropDown = query.getChild(WithClass("Options"));
        this.dropDownToggle = new HtmlClassSwitch(this.dropDown, "Expanded");

        this.button = query.getChild(WithTagName("BUTTON"));
        this.button.tabIndex = -1;

        this.label = query.getChild(WithClass("Label"));
        this.input = query.getChild(WithTagName("INPUT"));

        var options = new DomQuery(this.dropDown).getChildren(WithClass("Option"));

        for (var option of options)
            this.options.push(new MultipleSelectOption(option));
    }

    this.focus = function() {
        this.options[0].element.focus();
    }

    this.focusNext = function(option) {
        var target = option.element.nextSibling;

        if (target !== null && target.classList.contains("Option"))
            target.focus();
    }

    this.focusPrevious = function(option) {
        var target = option.element.previousSibling;

        if (target !== null && target.classList.contains("Option"))
            target.focus();
    }

    this.getName = function() {
        return this.input.name;
    }

    this.getValue = function() {
        return this.input.value;
    }

    this.handleKey = function(event) {
        if (event.ctrlKey && event.code === "Space") {
            this.toggleForm();
            event.stopPropagation();
        }
    }

    this.handleOptionKey = function(option, event) {
        if (event.code === "Space") {
            this.toggle(option);
            event.stopPropagation();
        }
        else if (event.code === "Enter") {
            this.toggle(option);

            if (this.isOpen())
                this.close();
        }
        else if (this.isOpen() && event.code === "Escape") {
            this.close();
        }
        else if (event.code === "ArrowUp") {
            this.focusPrevious(option);
            event.preventDefault();
        }
        else if (event.code === "ArrowDown") {
            this.focusNext(option);
            event.preventDefault();
        }
        else if (event.code === "Home") {
            this.options[0].element.focus();
            event.preventDefault();
        }
        else if (event.code === "End") {
            this.options[this.options.length - 1].element.focus();
            event.preventDefault();
        }
        else if (event.ctrlKey && event.code === "KeyA") {
            this.selectAll();
            event.preventDefault();
        }
    }

    this.isOpen = function() {
        return this.dropDownToggle.getStatus();
    }

    this.open = function() {
        var object = this;

        this.dropDownToggle.setStatus(true);
        this.dropDown.scrollIntoView({block: "nearest", inline: "nearest"});
        this.clickOutsideListener = connectClickOutsideListener(this.element, function(event) {object.toggleForm(); });

        this.focus();
    }

    this.recalculate = function() {
        var values = new Array();
        var captions = new Array();

        for (var option of this.options) {
            if (option.selected) {
                values.push(option.value);
                captions.push(option.element.innerHTML);
            }
        }

        this.setValue(values.join(","));
        this.label.innerHTML = captions.join(", ");
    }

    this.selectAll = function() {
        for (var option of this.options)
            option.setStatus(true);

        this.recalculate();
    }

    this.setValue = function(value) {
        this.input.value = value;

        this.input.dispatchEvent(new Event("input"));
        this.input.dispatchEvent(new Event("change"));
    }

    this.toggle = function(option) {
        option.toggle();
        this.recalculate();
    }

    this.toggleForm = function() {
        if (this.isOpen())
            this.close();
        else
            this.open();
    }

    this.element.tabIndex = 0;
    this.options = new Array();

    this.determineElements();
    this.recalculate();
    this.attachHandlers();
}

function MultipleSelectOption(element) {
    this.setStatus = function(status) {
        this.selected = status;

        this.classSwitch.setStatus(status);
        this.element.setAttribute("aria-selected", status);
    }

    this.toggle = function() {
        this.setStatus(!this.selected);
    }

    this.element = element;
    this.element.tabIndex = -1;

    this.value = this.element.dataset.Value;
    this.selected = element.classList.contains("Selected");

    this.classSwitch = new HtmlClassSwitch(this.element, "Selected");
    this.selected = this.classSwitch.getStatus();
}

interactivityRegistration.register("MultipleSelectDropDown", function (element) { return new MultipleSelectDropDown(element); });
