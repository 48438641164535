class TableRow {
  constructor(element, table) {
    this.element = element;
    this.element.tabIndex = "0";
    this.table = table;

    this.determineElements();
    this.attachHandlers();
  }

  isSelectable() {
    return this.rowSelectorCell !== null;
  }

  select(selectRange) {
    if (selectRange && this.table.lastSelected !== null)
      this.table.selectRange(Math.min(this.table.lastSelected, this.element.sectionRowIndex), Math.max(this.table.lastSelected, this.element.sectionRowIndex), true);
    else {
      this.table.selectRange(this.element.sectionRowIndex, this.element.sectionRowIndex, !this.selected);

      if (this.selected)
        this.table.lastSelected = this.element.sectionRowIndex;
    }

    this.table.fireSelectionChangedEvent();
  }

  determineElements() {
    this.rowSelectorCell = new DomQuery(this.element).getChild(WithClass("rowSelector"));

    if (this.rowSelectorCell !== null) {
      this.checkBoxField = new DomQuery(this.rowSelectorCell).getChild(WithClass("CheckBoxField"));
      this.checkBox = this.checkBoxField.childNodes[0];
    }
    else {
      this.checkBoxField = null;
      this.checkBox = null;
    }

    const quickActions = new DomQuery(this.element).getDescendant(WithClass("QuickActions"));

    if (quickActions !== null)
      this.quickActions = new QuickActions(quickActions, this.element);
    else
      this.quickActions = null;
  }


  attachHandlers() {
    this.addKeyEventHandlers();

    if (this.quickActions !== null)
      this.addQuickActionEventHandlers();

    if (this.table.rowSelectorEnabled)
      this.addRowSelectorEventHandlers();
  }

  addKeyEventHandlers() {
    this.element.addEventListener(
      "keydown",
      (event) => {
        if (event.target === this.element) {
          if (event.code === "ArrowUp") {
            if (this.element.previousSibling !== null)
              this.element.previousSibling.focus();

            event.stopHandling();
          }
          else if (event.code === "ArrowDown") {
            if (this.element.nextSibling !== null)
              this.element.nextSibling.focus();

            event.stopHandling();
          }
          else if (this.isSelectable() && event.code === "Space") {
            this.select(event.shiftKey);
            event.stopHandling();
          }
          else if (this.uri!== null && event.code === "Enter") {
            if (this.uri !== null)
              this.element.click();
          }
          else if (this.table.selectAll !== null && event.code === "KeyA" && event.ctrlKey) {
            this.table.selectAllRows(!event.shiftKey);
            this.table.fireSelectionChangedEvent();

            event.stopHandling();
          }
          else if (event.code === "Home") {
            this.element.parentNode.childNodes[0].focus();
            event.stopHandling();
          }
          else if (event.code === "End") {
            this.element.parentNode.childNodes[this.element.parentNode.childNodes.length - 1].focus();
            event.stopHandling();
          }
        }
      }
    );
  }

  addQuickActionEventHandlers() {
    for (const action of this.quickActions.actions) {
      action.addEventListener(
        "click",
        (event) => {
          event.stopPropagation();
          this.table.selectAllRows(false);

          this.selected = true;
          this.table.selectRow(this.checkBox);

          const actionComponent = this.table.getDescendant((component) => {
            return (component instanceof ObjectAction) && component.name === action.dataset.Name;
          });
          actionComponent.open();
        }
      );
    }
  }

  addRowSelectorEventHandlers() {
    if (this.checkBox !== null) {
      if (this.selected)
        this.table.selectRow(this.checkBox);

      this.checkBox.addEventListener(
        "change",
        (event) => {
          this.table.selectRow(this.checkBox);

          if (this.selected)
            this.table.lastSelected = this.element.sectionRowIndex;

          this.table.fireSelectionChangedEvent();
        }
      );

      this.checkBoxField.addEventListener(
        "click",
        (event) => {
          this.select(event.shiftKey);
          this.element.focus();

          event.stopPropagation();
          event.preventDefault();
        }
      );

      this.rowSelectorCell.addEventListener(
        "click",
        (event) => {
          event.stopPropagation();
        }
      );
    }
  }

  get uri() {
    return this.element.dataset.Uri ?? null;
  }

  get selected() {
    return this.checkBox.checked;
  }

  set selected(value) {
    this.checkBox.checked = value;
  }
}
