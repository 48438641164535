class NavigationEvent {
  constructor(uri) {
    this.uri = uri;
  }
}

class PageComponent {
  constructor(query) {
    this.query = query;
  }

  initialize(query) {
    this.element = this.load(query);
  }

  load(query) {
    return this.query(query);
  }

  reload(parent, query) {
    this.replace(parent, this.load(query));
  }

  replace(parent, element) {
    interactivityRegistration.detach(this.element);
    parent.replaceChild(element, this.element);
    this.element = element;
    interactivityRegistration.attach(element);
  }
}

class PageHandler {
  constructor(rootUri) {
    this.rootUri = rootUri;

    this.contents = new PageComponent((query) => { return query.getChild(WithTagName("MAIN")); });
    this.breadcrumbs = new PageComponent((query) => { return query.getChild(WithClass("Breadcrumbs")); });

    this.components = new Array();
    this.components.push(new PageComponent((query) => { return query.getChild(WithClass("Menu")); }));
    this.components.push(this.breadcrumbs);
    this.components.push(this.contents);
    this.components.push(new PageComponent((query) => { return query.getChild(WithClass("WebSiteToolbar")); }));
    this.components.push(new PageComponent((query) => { return query.getChild(WithClass("SearchComponent")); }));
    this.components.push(new PageComponent((query) => { return query.getChild(WithClass("Bookmarks")); }));

    this.initialize();
    this.addListeners();

    this.entityStore = new EntityStore();

    window.history.replaceState(
      { url: window.location.href },
      null,
      window.location.href
    );
  }

  addListeners() {
    addEventListener(
      "popstate",
      (event) => {
        if (event.state) {
          this.reload(window.location.href);
          event.preventDefault();
        }
      }
    );

    window.addEventListener(
      "click",
      (event) => {
        const link = event.target.closest("a");

        if (link && !event.ctrlKey && link.target !== "_blank" && link.getAttribute("href").startsWith(this.rootUri)) {
          event.preventDefault();
          this.load(link.getAttribute("href"));
        }
      }
    );
  }

  build() {
    this.loadingIndicator = new LoadingIndicator();
    this.loading = new HtmlClassSwitch(this.element, "Loading");

    this.loadingPage = document.createElement("div");
    this.loadingPage.classList.add("LoadingPage");
    this.loadingPage.appendChild(this.loadingIndicator.element);

    this.element.appendChild(this.loadingPage);
  }

  initialize() {
    this.element = document.getElementById("main");
    const query = new DomQuery(this.element);

    for (const component of this.components)
      component.initialize(query);

    this.build();
  }

  handleDocument(url, text) {
    const newDocument = new DOMParser().parseFromString(text, "text/html");

    if (this.loadSupported() && !this.reloadRequired(newDocument)) {
      const main = new DomQuery(newDocument).getDescendant(WithId("main"));
      const query = new DomQuery(main);

      if (!this.components.some((component) => component.load(query) === null)) {
        document.title = newDocument.title;
        document.body.dataset.Source = newDocument.body.dataset.Source;

        for (const component of this.components)
          component.reload(this.element, query);

        application.synchronizationCenter.initialize();
        distributeEvent(new NavigationEvent(url));
      }
      else
        this.reloadDocument(newDocument);
    }
    else
      this.reloadDocument(newDocument);
  }

  reloadDocument(newDocument) {
    interactivityRegistration.detach(document.documentElement);
    document.documentElement.innerHTML = newDocument.documentElement.innerHTML;
    interactivityRegistration.attach(document.documentElement);

    application.initialize();
  }

  async fallbackToOfflinePage(uri, error) {
    const entity = await this.entityStore.get(uri);

    if (entity !== undefined) {
      const renderer = new TemplateRenderer();
      const contents = renderer.renderTemplate(entity.Template, entity.Value);

      const breadcrumbs = this.breadcrumbs.element.cloneNode(true);
      breadcrumbs.firstChild.innerHTML = "";

      const link = document.createElement("a");
      link.href = entity.Uri;
      link.innerText = entity.Title;

      const item = document.createElement("li");
      item.appendChild(document.createElement("span"));
      item.firstChild.appendChild(link);

      const list = document.createElement("ol");
      list.appendChild(item);

      breadcrumbs.firstChild.appendChild(list);

      this.contents.replace(this.element, contents);
      this.breadcrumbs.replace(this.element, breadcrumbs);

      window.history.pushState(
        { url: uri },
        null,
        uri
      );
    }
    else {
      application.toastBox.addMessage(new ToastMessage("Failed to load page", "Error"));
      console.error(error);
    }
  }

  fetch(url) {
    if (application.online)
      return fetch(
        url,
        {
          method: "GET",
          headers: {
            "Accept": "text/html"
          }
        }
      );
    else
      return Promise.reject(new Error("The application is offline"));
  }

  load(url) {
    const currentUrl = new URL(window.location.href);
    const navigationUrl = new URL(url);

    if (!this.loadingIndicator.getStatus()) {
      if (navigationUrl.pathname === currentUrl.pathname) {
        if (navigationUrl.hash.length > 0 || navigationUrl.search.length > 0)
          window.location = navigationUrl;
      }
      else {
        this.setStatus(true);

        this.fetch(url)
          .then(response => {
            window.history.pushState(
              { url: response.url },
              null,
              response.url
            );

            return response.text();
          })
          .then(text => this.handleDocument(url, text))
          .catch(error => this.fallbackToOfflinePage(url, error))
          .finally(() => this.setStatus(false));
      }
    }
  }

  setStatus(loading) {
    this.loading.setStatus(loading);
    this.loadingIndicator.setStatus(loading);
  }

  reload(url) {
    this.setStatus(true);

    this.fetch(url)
      .then(async response => {
        if (response.ok)
          this.handleDocument(url, await response.text());
      })
      .catch(error => this.fallbackToOfflinePage(url, error))
      .finally(() => this.setStatus(false));
  }

  reloadRequired(newDocument) {
    if (newDocument.head.childNodes.length === document.head.childNodes.length) {
      for (let index = 0; index < newDocument.head.childNodes.length; index++) {
        const left = newDocument.head.childNodes[index];
        const right = document.head.childNodes[index];

        if (left.tagName !== "TITLE" && !left.isEqualNode(right)) {
          return true;
        }
      }

      return false;
    }
    else
      return true;
  }

  loadSupported() {
    return !this.components.some((component) => component.element === null);
  }

  getPopUps() {
    return new DomQuery(this.contents.element).getChildren(WithClass("PopUp"));
  }
}
