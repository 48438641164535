function EmailViewer (element) {
    WebPageComponent.call(this, element);
    this.setInlineSources = function() {
        if ((this.document != null) & (this.attachments != null)) {
            var tags = this.document.getElementsByTagName("img");

            for (var i = 0; i < tags.length; i++) {
                var tag = tags[i];
                var split = tag.src.split(":");

                if ((split.length == 2) && (split[0] == "cid"))
                    tag.src = this.attachments.dataset.Uri + split[1];
            }
        }
    }

    this.resizeIframe = function() {
        if (this.iframe != null) {
            this.iframe.style.height = null;
            this.iframe.style.height = this.document.body.scrollHeight + "px";
        }
    }

    this.determineElements = function() {
        var query = new DomQuery(this.element);

        this.iframe = query.getChild(WithTagName("IFRAME"));
        this.attachments = query.getChild(WithClass("Attachments"));

        if (this.iframe != null)
            this.document = this.iframe.contentWindow.document;
    }

    this.addEventListeners = function() {
        var object = this;

        if (this.iframe != null)
            window.addEventListener("resize", function() { object.resizeIframe(); }, false);
    }

    this.determineElements();
    this.addEventListeners();
    this.setInlineSources();
    this.resizeIframe();
}

interactivityRegistration.register("EmailViewer", function (element) { return new EmailViewer(element); });
